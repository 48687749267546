import React from "react"
import App from "../components/App"

export default function Disclaimer() {
	return (
		<App>
		<div className='right-frame' style={{ padding: 20 }}>
			<h2>Disclaimer</h2>
			<p>&nbsp;</p>
			<p>
				CODREAMS.ORG IS A DIGITAL PUBLISHING WEBSITE. IT DOES NOT PROVIDE PERSONAL HEALTH OR MEDICAL ADVICE. YOU SHOULD CALL YOUR LOCAL EMERGENCY SERVICES IMMEDIATELY IF YOU ARE FACING A MEDICAL EMERGENCY, OR VISIT EMERGENCY SECTION OF NEAREST HOSPITAL OR CLINIC. BEFORE YOU START ANY NUTRITION, DIET, EXERCISE, FITNESS, MEDICAL OR WELLNESS PROGRAM, YOU SHOULD CONSULT YOUR HEALTHCARE PROVIDER. 
			</p>
			<p>&nbsp;</p>
			<p>
				The content of the website is collaboration of its contributors, including text, graphics, images, and other material contained on the website, is general in nature and for information purposes only and it does not constitute medical advice. The content is not intended to be a substitute for professional medical advice, diagnosis, or treatment. You should always consult your doctor or other qualified healthcare provider with any questions you may have regarding a medical condition, procedure, or treatment, whether it is a prescription medication, over-the-counter drug, vitamin, supplement, or herbal alternative. CoDreams.org makes no guarantees about the efficacy or safety of products or treatments described on the website’s content. Health conditions and drug information contained are subject to change and are not intended to cover all possible uses, directions, precautions, warnings, drug interactions, allergic reactions, or adverse effects. CoDreams.org does not recommend or endorse any specific test, clinician, clinical care provider, product, procedure, opinion, service, or other information that may be mentioned in the website and its content.
			</p>
		</div>
		</App>
	)
}